[data-v-7e7f73e8] .el-input__inner {
  background-color: transparent !important;
  border: 0;
  font-family: "PingFangSt";
  color: #fff;
  font-size: 0.698vw;
  font-style: italic;
  text-shadow: 0 0 0.279vw rgba(0, 106, 255, 0.8);
  padding: 0.116vw;
  font-weight: bold;
}
[data-v-7e7f73e8] .el-select__caret {
  color: #fff !important;
  font-size: 0.581vw !important;
}
[data-v-7e7f73e8] .el-input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#app[data-v-7e7f73e8] {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.fullScreen[data-v-7e7f73e8] {
  position: fixed;
  top: 0.698vw;
  right: 3.047vw;
  color: #E3F3FF;
  z-index: 999;
}
.fullScreen i[data-v-7e7f73e8] {
  font-size: 0.419vw !important;
}
#app > .main[data-v-7e7f73e8] {
  width: 100vw;
  height: 30vw;
  background: url(../../static/img/backgroundAllL.9aefda4f.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 1.628vw 3.721vw 0.581vw 3.721vw;
  position: relative;
}
#app > .main > div[data-v-7e7f73e8] {
  width: 100%;
  height: 100%;
}
#app > .main .center[data-v-7e7f73e8] {
  width: calc(100% - 23.256vw - 23.256vw);
}
#app > .main .center > div[data-v-7e7f73e8] {
  width: 100%;
  height: 100%;
}
#app > .main .earthMap[data-v-7e7f73e8] {
  position: relative;
  height: 100%;
  margin-bottom: 0.521vw;
}
#app > .main .earthMap .wordMapBox[data-v-7e7f73e8] {
  width: 9.302vw !important;
  height: 1.279vw !important;
}
#app > .main .earthMap .wordMapBox > div[data-v-7e7f73e8] {
  width: 3.721vw;
  height: 1.279vw;
  font-size: 0.512vw;
  line-height: 1.279vw;
}
#app > .main .left[data-v-7e7f73e8],
#app > .main .right[data-v-7e7f73e8] {
  width: 23.256vw;
}
#app > .main .left .header[data-v-7e7f73e8],
#app > .main .right .header[data-v-7e7f73e8] {
  height: 1.395vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #CDD6e3;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#app > .main .left .header .dateTime[data-v-7e7f73e8],
#app > .main .right .header .dateTime[data-v-7e7f73e8] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
#app > .main .left .header .dateTime span[data-v-7e7f73e8]:first-child,
#app > .main .right .header .dateTime span[data-v-7e7f73e8]:first-child {
  font-size: 0.419vw;
}
#app > .main .left .header .dateTime span[data-v-7e7f73e8]:last-child,
#app > .main .right .header .dateTime span[data-v-7e7f73e8]:last-child {
  font-size: 0.279vw;
}
#app > .main .left .header .statistics[data-v-7e7f73e8],
#app > .main .right .header .statistics[data-v-7e7f73e8] {
  width: 4.651vw;
  height: 1.395vw;
  margin-left: 2.326vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
#app > .main .left .header .statistics > div[data-v-7e7f73e8]:first-child,
#app > .main .right .header .statistics > div[data-v-7e7f73e8]:first-child {
  width: 1.395vw;
  height: 1.395vw;
}
#app > .main .left .header .statistics > div:first-child img[data-v-7e7f73e8],
#app > .main .right .header .statistics > div:first-child img[data-v-7e7f73e8] {
  width: 100%;
  height: 100%;
}
#app > .main .left .header .statistics > div[data-v-7e7f73e8]:last-child,
#app > .main .right .header .statistics > div[data-v-7e7f73e8]:last-child {
  width: 3.256vw;
  height: 1.395vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
#app > .main .left .header .statistics > div:last-child span[data-v-7e7f73e8]:first-child,
#app > .main .right .header .statistics > div:last-child span[data-v-7e7f73e8]:first-child {
  font-size: 0.326vw;
  color: #E3F3FF;
}
#app > .main .left .header .statistics > div:last-child span[data-v-7e7f73e8]:last-child,
#app > .main .right .header .statistics > div:last-child span[data-v-7e7f73e8]:last-child {
  font-size: 0.651vw;
  color: #01E3D8;
}
#app > .main .left .header .statistics > div:last-child span:last-child span[data-v-7e7f73e8],
#app > .main .right .header .statistics > div:last-child span:last-child span[data-v-7e7f73e8] {
  font-size: 0.256vw;
  color: #E3F3FF;
}
#app > .main .left .header .switchProject[data-v-7e7f73e8],
#app > .main .right .header .switchProject[data-v-7e7f73e8] {
  height: 1.395vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-left: 4.977vw;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
#app > .main .left .body[data-v-7e7f73e8],
#app > .main .right .body[data-v-7e7f73e8] {
  margin: 0.814vw 0;
  width: 100%;
  height: calc(100% - 1.395vw - 0.814vw - 0.814vw);
}
#app > .main .left .leftBody[data-v-7e7f73e8],
#app > .main .right .leftBody[data-v-7e7f73e8] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-line-pack: justify;
      align-content: space-between;
}
#app > .main .left .leftBody > div[data-v-7e7f73e8],
#app > .main .right .leftBody > div[data-v-7e7f73e8] {
  width: 100%;
}
#app > .main .left .leftBody .left1[data-v-7e7f73e8],
#app > .main .right .leftBody .left1[data-v-7e7f73e8] {
  height: 4.302vw;
  margin-bottom: 0.465vw;
}
#app > .main .left .leftBody .left2[data-v-7e7f73e8],
#app > .main .right .leftBody .left2[data-v-7e7f73e8] {
  height: 7.442vw;
  margin-bottom: 0.465vw;
}
#app > .main .left .leftBody .left3[data-v-7e7f73e8],
#app > .main .left .leftBody .left4[data-v-7e7f73e8],
#app > .main .right .leftBody .left3[data-v-7e7f73e8],
#app > .main .right .leftBody .left4[data-v-7e7f73e8] {
  height: 8.372vw;
  width: 11.395vw;
  margin-bottom: 0.465vw;
}
#app > .main .left .leftBody .left5[data-v-7e7f73e8],
#app > .main .left .leftBody .left6[data-v-7e7f73e8],
#app > .main .right .leftBody .left5[data-v-7e7f73e8],
#app > .main .right .leftBody .left6[data-v-7e7f73e8] {
  height: 3.605vw;
  width: 11.395vw;
  margin-bottom: 0.465vw;
}
#app > .main .left .rightBody[data-v-7e7f73e8],
#app > .main .right .rightBody[data-v-7e7f73e8] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-line-pack: justify;
      align-content: space-between;
}
#app > .main .left .rightBody > div[data-v-7e7f73e8],
#app > .main .right .rightBody > div[data-v-7e7f73e8] {
  height: 5.814vw;
  width: 11.395vw;
}
#app > .main .leftTop[data-v-7e7f73e8] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 0.326vw;
  padding-top: 0.233vw;
}
#app > .main .leftTop > div > div[data-v-7e7f73e8]:first-child {
  margin-bottom: 0.233vw;
}
#app > .main .leftTop > div[data-v-7e7f73e8]:nth-child(1) {
  width: 4.186vw;
  margin-right: 0.465vw;
}
#app > .main .leftTop > div[data-v-7e7f73e8]:nth-child(2) {
  width: 13.023vw;
  margin-right: 0.465vw;
}
#app > .main .leftTop > div[data-v-7e7f73e8]:nth-child(3) {
  width: 4.186vw;
}
#app > .main .itemLLDB[data-v-7e7f73e8] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
#app > .main .itemLLDB div[data-v-7e7f73e8] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#app > .main .itemLLDB span[data-v-7e7f73e8] {
  color: #00aaff;
  margin: 0 0.233vw;
}
#app > .main .tableBox[data-v-7e7f73e8] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 0.465vw;
}
#app > .main .tableBox .tableBoxMain[data-v-7e7f73e8] {
  width: 50%;
  height: 100%;
}
#app > .main .tableBox .tableBoxMain .tableHeader[data-v-7e7f73e8] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-bottom: 0.023vw solid #00aaff;
  height: 1.047vw;
  font-size: 0.372vw;
}
#app > .main .tableBox .tableBoxMain .tableHeader span[data-v-7e7f73e8] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #00aaff;
}
#app > .main .tableBox .tableBoxMain .tableHeader > span[data-v-7e7f73e8]:first-child {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
#app > .main .tableBox .tableBoxMain .tableHeader > span[data-v-7e7f73e8]:last-child {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
#app > .main .tableBox .tableBoxMain .tableBodyFather[data-v-7e7f73e8] {
  width: 100%;
  height: calc(100% - 1.047vw);
  overflow: hidden;
}
#app > .main .tableBox .tableBoxMain .tableBody[data-v-7e7f73e8] {
  height: 100%;
  width: 100%;
  font-size: 0.326vw;
}
#app > .main .tableBox .tableBoxMain .tableBody .tableTh[data-v-7e7f73e8] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 1.047vw;
  font-size: 0.372vw;
}
#app > .main .tableBox .tableBoxMain .tableBody .tableTh > span[data-v-7e7f73e8] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #E3F3FF;
}
#app > .main .tableBox .tableBoxMain .tableBody .tableTh > span[data-v-7e7f73e8]:first-child {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
#app > .main .tableBox .tableBoxMain .tableBody .tableTh > span[data-v-7e7f73e8]:last-child {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
#app > .main .tableBox .tableBoxMain .tableBody .tableTh .indexIcon[data-v-7e7f73e8] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 0.465vw;
  height: 0.465vw;
  padding: 0.14vw 0.233vw;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  border: 1px solid rgba(228, 241, 250, 0.2);
  background: radial-gradient(93.47% 77.22% at 0% 100%, rgba(214, 243, 255, 0.8) 0%, rgba(160, 210, 255, 0.231372549) 36.93%, rgba(255, 255, 255, 0) 100%, rgba(53, 67, 88, 0) 100%), rgba(0, 133, 255, 0.7019607843);
  margin-right: 0.116vw;
}
#app > .main .tableBox .tableBoxMain[data-v-7e7f73e8]:first-child {
  margin-right: 0.465vw;
}